import { createAction, props } from "@ngrx/store";
import { IshtarTimeRegistration } from "../../domain/models/time/ishtarTimeRegistration";

export const getIshtarTimes = createAction(
    "[IshtarTimes] Get ishtarTimes",
    props<{
        callback?: () => void;
    }>()
);

export const getIshtarTimesResolved = createAction(
    "[IshtarTimes] Get ishtarTimes resolved",
    props<{
        ishtarTimes: IshtarTimeRegistration[];
    }>()
);

export const getIshtarTimesByTaskId = createAction(
    "[IshtarTime] Get ishtarTimes by taskId",
    props<{
        taskId: string;
        callback?: () => void;
    }>()
);

export const getIshtarTimesByTaskIdResolved = createAction(
    "[IshtarTime] Get ishtarTimes by taskId resolved",
    props<{
        ishtarTimes: IshtarTimeRegistration[];
    }>()
);

export const deleteIshtarTime = createAction(
    "[IshtarTime] Delete ishtarTime",
    props<{
        ishtarTimeRegistrationId: string;
        callback?: () => void;
    }>()
);

export const deleteIshtarTimeResolved = createAction(
    "[IshtarTime] Delete ishtarTime resolved",
    props<{
        ishtarTimeRegistrationId: string;
    }>()
);

export const updateIshtarTime = createAction(
    "[IshtarTime] Update ishtarTime",
    props<{
        ishtarTime: IshtarTimeRegistration;
        callback?: (ishtarTime: IshtarTimeRegistration) => void;
    }>()
);

export const updateIshtarTimeResolved = createAction(
    "[IshtarTime] Update ishtarTime resolved",
    props<{
        updatedIshtarTime: IshtarTimeRegistration;
    }>()
);

export const addIshtarTime = createAction(
    "[IshtarTime] Add ishtarTime",
    props<{
        ishtarTime: IshtarTimeRegistration;
        callback?: (addedIshtarTime: IshtarTimeRegistration) => void;
    }>()
);

export const addIshtarTimeResolved = createAction(
    "[IshtarTime] Add ishtarTime resolved",
    props<{
        addedIshtarTime: IshtarTimeRegistration;
    }>()
);

// export const deleteIshtarTimes = createAction(
//     "[IshtarTimes] Delete ishtarTimes",
//     props<{ ids: string[]; callback?: () => void }>()
// );

// export const deleteIshtarTimesResolved = createAction(
//     "[IshtarTimes] Delete ishtarTimes resolved",
//     props<{ ishtarTimeIds: string[] }>()
// );

// export const updateIshtarTimes = createAction(
//     "[IshtarTimes] Update ishtarTimes",
//     props<{ ishtarTimes: IshtarTimeRegistration[]; callback?: () => void }>()
// );

// export const updateIshtarTimesResolved = createAction(
//     "[IshtarTimes] Update ishtarTimes resolved",
//     props<{ updatedIshtarTimes: IshtarTimeRegistration[] }>()
// );

// export const addIshtarTimes = createAction(
//     "[IshtarTimes] Add ishtarTimes",
//     props<{
//         ishtarTimes: IshtarTimeRegistration[];
//         callback?: (addedIshtarTimes: IshtarTimeRegistration[]) => void;
//     }>()
// );

// export const addIshtarTimesResolved = createAction(
//     "[IshtarTimes] Add ishtarTimes resolved",
//     props<{ addedIshtarTimes: IshtarTimeRegistration[] }>()
// );
