import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class SnackBarService {
    private readonly dismissTimeout = 3000;

    constructor(private snackBar: MatSnackBar) { }

    public showSuccessSnackbar(message: string, actionText?: string): void {
        this.showSnackbar('app-notification-success', this.dismissTimeout, message, actionText);
    }

    public showErrorSnackbar(message: string, actionText?: string): void {
        this.showSnackbar('app-notification-error', this.dismissTimeout, message, actionText);
    }

    public showDefaultSnackbar(message: string, actionText?: string): void {
        this.showSnackbar(undefined, this.dismissTimeout, message, actionText);
    }

    private showSnackbar(panelClass: string | undefined, dismissTimeout: number, message: string, actionText: string | undefined): void {
        this.snackBar
            .open(message, actionText, { panelClass })
            ._dismissAfter(dismissTimeout);
    }
}