import { GroupUser } from 'processdelight-angular-components';
import { BaseItem } from '../base-item';
import { Lookup } from '../lookup';
import { AppItemInfo } from './appItemInfo';

export class DMSMetadata {
  ishtarDMSMetadataId!: string;
  metadataParameter!: Lookup;
  item!: Lookup;
  value?: string;
  numberValue?: number;
  dateTimeValue?: Date;
  groupUserValue?: GroupUser;
  constructor(obj: Partial<DMSMetadata>) {
    Object.assign(this, obj);
  }
}

export class DMSItem extends BaseItem {
  ishtarDMSItemId!: string;
  title!: string;
  fileLocation!: string;
  sharepointId!: string;
  linkedItems?: Lookup[];
  library!: Lookup;
  appItemInfo!: AppItemInfo;
  appItems?: DMSAppItem[];
  metadata?: DMSMetadata[];

  constructor(obj: Partial<DMSItem>) {
    super(obj);
    Object.assign(this, obj);
  }
}

export class DMSAppItem extends BaseItem {
  ishtarDMSAppItemId!: string;
  item!: Lookup;
  tableName!: string;
  value!: string;
  constructor(obj: Partial<DMSAppItem>) {
    super(obj);
    Object.assign(this, obj);
  }
}

export class DMSMetadataParameter extends BaseItem {
  ishtarDMSMetadataParameterId!: string;
  title!: string;
  tooltip!: string;
  type!: string;
  required!: boolean;
  fileNameParam!: boolean;
  createdOnParam!: boolean;
  createdByParam!: boolean;
  modifiedOnParam!: boolean;
  modifiedByParam!: boolean;
  showGroups!: boolean;
  showUsers!: boolean;
  format!: string;
  maxLength!: number;
  multi!: boolean;
  constructor(obj: Partial<DMSMetadataParameter>) {
    super(obj);
    Object.assign(this, obj);
  }
}
