import { Lookup } from '../lookup';
import { DependencyValue } from './dependencyValue';

export class TaskDependency {
  ishtarTaskDependencyId!: string;
  task!: Lookup;
  dependentTask!: Lookup;
  dependencyType!: Lookup;
  dependencyValues?: DependencyValue[];

  constructor(obj: Partial<TaskDependency>) {
    delete (obj as any).id;
    Object.assign(this, obj);
  }
}
