import { GroupUser } from 'processdelight-angular-components';
import { BaseItem } from '../base-item';
import { Lookup } from '../lookup';

export class TaskLog extends BaseItem {
  ishtarTaskLogId?: string;
  title!: string;
  task?: Lookup;
  user?: GroupUser;
  description?: string;
  logType!: Lookup;

  constructor(obj: Partial<TaskLog>) {
    super(obj);
    Object.assign(this, obj);
  }
}
