import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  private errorSubject = new Subject<string[]>();
  private errorMessages: string[] = [];
  public retrySubject = new BehaviorSubject<boolean>(false);

  public getErrorStream(): Observable<string[]> {
    return this.errorSubject.asObservable();
  }

  public clearErrors(): void {
    this.errorMessages = [];
  }

  public handleError(error: any): void {
    console.debug('Handling error:', error);

    if (error.error) {
      console.debug('Error message from error object:', error.error);
      this.errorMessages.push(error.error);
    } else if (error.statusText) {
      console.debug('Error message from statusText:', error.statusText);
      this.errorMessages.push(error.statusText);
    } else {
      console.debug('An unexpected error occurred.');
      this.errorMessages.push('An unexpected error occurred.');
    }

    this.errorSubject.next(this.errorMessages);
  }
}
