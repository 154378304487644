import { Action, createReducer, on } from '@ngrx/store';
import {
  KanbanContract,
  Task,
  TaskContract,
} from '../../domain/models/task/task';
import {
  getTasksResolved,
  addTaskResolved,
  deleteTaskResolved,
  updateTaskResolved,
  addTaskLogsResolved,
  removeDocumentsResolved as removeDocumentsResolved,
  getAllTasksResolved,
  updateStatusesResolved,
  addStatusesResolved,
  linkSubtasksResolved,
  getKanbanResolved,
  getSubTasksResolved,
  getTaskDocumentsResolved,
  deleteStatusResolved,
  getStatusesResolved,
  createTaskDocumentsResolved,
} from './task.actions';
import { AppState } from 'src/app/app.reducer';
import { Status } from '../../domain/models/task/status';
import { DMSItem } from '../../domain/models/dms/dms-item.model';

export const featureSlice = 'task';

export interface State {
  tasks: Task[];
  allTasks: TaskContract[];
  task?: Task;
  ishtarTaskId?: string;
  pageNumber: number;
  totalRecordCount: number;
  totalRecordCountMyTasks: number;
  assignedToMe: boolean;
  taskDocuments: DMSItem[] | undefined;
  subTasks: Task[] | undefined;
  lastSavedTaskId?: string;
  statuses: Status[];
  kanbanBoard?: KanbanContract[];
}
const defaultState: State = {
  tasks: [],
  allTasks: [],
  task: undefined,
  ishtarTaskId: undefined,
  pageNumber: 0,
  totalRecordCount: 0,
  totalRecordCountMyTasks: 0,
  assignedToMe: false,
  lastSavedTaskId: undefined,
  taskDocuments: undefined,
  subTasks: undefined,
  statuses: [],
  kanbanBoard: undefined,
};

export const initialState: State = defaultState;

const taskReducer = createReducer(
  initialState,
  on(
    getTasksResolved,
    (
      state,
      { result, pageNumber, totalRecordCount, resetPaging, assignedToMe }
    ) => ({
      ...state,
      tasks: [...result].concat([...state.tasks]),
      pageNumber,
      totalRecordCount: assignedToMe
        ? state.totalRecordCount
        : totalRecordCount,
      totalRecordCountMyTasks: assignedToMe
        ? totalRecordCount
        : state.totalRecordCountMyTasks,
      assignedToMe,
    })
  ),
  on(getAllTasksResolved, (state, { tasks }) => ({
    ...state,
    allTasks: tasks,
  })),
  on(addTaskResolved, (state, { addedTask }) => ({
    ...state,
    tasks: [...state.tasks]
      .concat(!addedTask.isTaskTemplate ? [addedTask] : [])
      .map((t) => {
        if (t.ishtarTaskId == addedTask.parentTask?.id) {
          return new Task({
            ...t,
            subTasks: t.subTasks
              ? t.subTasks?.concat([addedTask])
              : [addedTask],
          });
        } else {
          return t;
        }
      }),
    allTasks: state.allTasks.concat([
      {
        id: addedTask.ishtarTaskId,
        name: addedTask.title,
        number: addedTask.number,
        status: state.statuses?.find(
          (s) => s.ishtarStatusId === addedTask.status?.id
        ),
        parentTask: addedTask.parentTask,
      },
    ]),
    lastSavedTaskId: addedTask.ishtarTaskId,
  })),
  // on(addSubtaskResolved, (state, { addedTask }) => ({
  //   ...state,
  //   tasks: [addedTask].concat([...state.tasks]),
  // })),
  on(deleteTaskResolved, (state, { ishtarTaskId }) => ({
    ...state,
    tasks: state.tasks.filter(
      (t) => t.isDeleted || t.ishtarTaskId != ishtarTaskId
    ),
    subTasks: state.subTasks
      ? [
          ...state.subTasks.filter(
            (t) => t.isDeleted || t.ishtarTaskId != ishtarTaskId
          ),
        ]
      : undefined,
    allTasks: state.allTasks.filter((t) => t.isDeleted || t.id != ishtarTaskId),
  })),
  on(updateTaskResolved, (state, task) => ({
    ...state,
    tasks: state.tasks.map((t) =>
      t.ishtarTaskId == task.ishtarTaskId ? task : t
    ),
    allTasks: state.allTasks.map((t) =>
      t.id == task.ishtarTaskId
        ? new TaskContract({
            id: task.ishtarTaskId,
            name: task.title,
            status: state.statuses?.find(
              (s) => s.ishtarStatusId === task.status?.id
            ),
            parentTask: task.parentTask,
            number: task.number,
          })
        : t
    ),
    kanbanBoard:
      state.kanbanBoard?.map((t) =>
        t.id == task.ishtarTaskId
          ? new KanbanContract({
              id: task.ishtarTaskId,
              name: task.title,
              status: task.status,
            })
          : t
      ) || undefined,
  })),
  on(linkSubtasksResolved, (state, { subtasks }) => ({
    ...state,
    subTasks: state.subTasks
      ? [
          ...state.subTasks,
          ...subtasks.filter(
            (subtask) =>
              !state.subTasks?.some(
                (t) => t.ishtarTaskId === subtask.ishtarTaskId
              )
          ),
        ]
      : subtasks,
    tasks: state.tasks.map((t) => {
      if (t.ishtarTaskId == subtasks[0].parentTask?.id) {
        return new Task({
          ...t,
          subTasks: t.subTasks ? t.subTasks?.concat(subtasks) : subtasks,
        });
      } else if (t.ishtarTaskId == subtasks[0].ishtarTaskId) {
        return new Task({
          ...t,
          parentTask: {
            id: subtasks[0].parentTask!.id,
            name: subtasks[0].parentTask!.name,
          },
        });
      } else {
        return t;
      }
    }),
  })),
  on(addTaskLogsResolved, (state, { logs }) => ({
    ...state,
    tasks: state.tasks.map((t) => {
      const taskLogs = logs.filter((l) => l.task?.id === t.ishtarTaskId);
      return taskLogs.length > 0 ? new Task({ ...t, logs: taskLogs }) : t;
    }),
  })),
  on(createTaskDocumentsResolved, (state, { contract }) => ({
    ...state,
    taskDocuments: state.taskDocuments
      ? state.taskDocuments.concat([...(contract.createdItems ?? [])])
      : [...(contract.createdItems ?? [])],
  })),
  on(removeDocumentsResolved, (state, { taskId, dmsAppItemIds }) => ({
    ...state,
    tasks: state.tasks.map((t) => {
      if (t.ishtarTaskId !== taskId) return t;
      return new Task({
        ...t,
        taskDocuments: t.taskDocuments?.filter((d) =>
          dmsAppItemIds.some((id) => id === d.appItemInfo.id)
        ),
      });
    }),
  })),
  on(deleteStatusResolved, (state, { ishtarStatusId }) => {
    return {
      ...state,
      statuses: state.statuses?.filter(
        (s) => s.ishtarStatusId !== ishtarStatusId
      ),
    };
  }),
  on(updateStatusesResolved, (state, { updatedStatuses }) => ({
    ...state,
    statuses: state.statuses?.map(
      (s) =>
        updatedStatuses.find((u) => s.ishtarStatusId === u.ishtarStatusId) ?? s
    ),
  })),
  on(addStatusesResolved, (state, { addedStatuses }) => ({
    ...state,
    statuses: addedStatuses.concat([...(state.statuses ?? [])]),
  })),
  on(getKanbanResolved, (state, { tasks }) => ({
    ...state,
    kanbanBoard: tasks,
  })),
  on(getSubTasksResolved, (state, { subTasks }) => ({
    ...state,
    subTasks: state.subTasks
      ? [
          ...state.subTasks,
          ...subTasks.filter(
            (subtask) =>
              !state.subTasks?.some(
                (t) => t.ishtarTaskId === subtask.ishtarTaskId
              )
          ),
        ]
      : subTasks,
  })),
  on(getTaskDocumentsResolved, (state, { taskDocuments }) => ({
    ...state,
    taskDocuments,
  })),
  on(getStatusesResolved, (state, { statuses }) => ({
    ...state,
    statuses,
  }))
);

export function Reducer(state: State | undefined, action: Action) {
  return taskReducer(state, action);
}

export const taskState = (state: AppState) => state.coreFeature.task;
