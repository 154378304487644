import { GroupUser } from "processdelight-angular-components";

export class Attendee {
  groupUser?: GroupUser;
  externalUserEmail?: string;
  response?: ResponseType;

  constructor(obj: Partial<Attendee>) {
    Object.assign(this, obj);
  }
}
