import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  addDependencies,
  addDependenciesResolved,
  addDependencyValues,
  addDependencyValuesResolved,
  removeDependencies,
  removeDependenciesResolved,
  removeDependencyValues,
  removeDependencyValuesResolved,
  updateDependencies,
  updateDependenciesResolved,
  updateDependencyValues,
  updateDependencyValuesResolved,
} from './dependency.actions';
import { of } from 'rxjs';
import { DependencyApiService } from '../../services/dependency-api.service';

@Injectable({ providedIn: 'root' })
export class DependencyEffects {
  constructor(
    private actions$: Actions,
    private dependencyApiService: DependencyApiService,
  ) {}

  addDependency = createEffect(() =>
    this.actions$.pipe(
      ofType(addDependencies),
      mergeMap(({ dependencies, callback, error }) =>
        this.dependencyApiService.addDependencies(dependencies).pipe(
          tap((dependencies) =>
            callback ? callback(dependencies) : undefined
          ),
          mergeMap((addedDependencies) =>
            of(addDependenciesResolved({ addedDependencies }))
          ),
          catchError((e) => {
            if (error) {
              error(e);
            }
            return [];
          })
        )
      )
    )
  );

  updateDependencies = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDependencies),
      switchMap(({ dependencies, callback, error }) =>
        this.dependencyApiService.updateDependencies(dependencies).pipe(
          tap((dependencies) =>
            callback ? callback(dependencies) : undefined
          ),
          switchMap((updatedDependencies) =>
            of(updateDependenciesResolved({ updatedDependencies }))
          ),
          catchError((e) => {
            if (error) {
              error(e);
            }
            return [];
          })
        )
      )
    )
  );

  removeDependency = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDependencies),
      switchMap(({ ishtarTaskDependencyIds, callback, error }) =>
        this.dependencyApiService
          .removeDependencies(ishtarTaskDependencyIds)
          .pipe(
            tap((ishtarTaskDependencyIds) =>
              callback ? callback(ishtarTaskDependencyIds) : undefined
            ),
            switchMap((ishtarTaskDependencyIds) =>
              of(removeDependenciesResolved({ ishtarTaskDependencyIds }))
            ),
            catchError((e) => {
              if (error) {
                error(e);
              }
              return [];
            })
          )
      )
    )
  );

  updateDependencyValue = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDependencyValues),
      switchMap(({ dependencyValues, callback, error }) =>
        this.dependencyApiService.updateDependencyValue(dependencyValues).pipe(
          tap((dependencyValues) =>
            callback ? callback(dependencyValues) : undefined
          ),
          switchMap((updatedDependencyValues) =>
            of(updateDependencyValuesResolved({ updatedDependencyValues }))
          ),
          catchError((e) => {
            if (error) {
              error(e);
            }
            return [];
          })
        )
      )
    )
  );

  addDependencyValue = createEffect(() =>
    this.actions$.pipe(
      ofType(addDependencyValues),
      switchMap(({ dependencyValues, callback, error }) =>
        this.dependencyApiService.addDependencyValue(dependencyValues).pipe(
          tap((dependencyValues) =>
            callback ? callback(dependencyValues) : undefined
          ),
          switchMap((addedDependencyValues) =>
            of(addDependencyValuesResolved({ addedDependencyValues }))
          ),
          catchError((e) => {
            if (error) {
              error(e);
            }
            return [];
          })
        )
      )
    )
  );

  removeDependencyValue = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDependencyValues),
      switchMap(({ ishtarDependencyValueIds, callback, error }) =>
        this.dependencyApiService
          .removeDependencyValue(ishtarDependencyValueIds)
          .pipe(
            tap((ishtarDependencyValueIds) =>
              callback ? callback(ishtarDependencyValueIds) : undefined
            ),
            switchMap((ishtarDependencyValueIds) =>
              of(removeDependencyValuesResolved({ ishtarDependencyValueIds }))
            ),
            catchError((e) => {
              if (error) {
                error(e);
              }
              return [];
            })
          )
      )
    )
  );
}
