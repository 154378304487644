import { BaseItem } from '../../base-item';
import { ApprovalFlow } from './approvalflow';

export class ApprovalFlowTemplate extends BaseItem {
  ishtarTaskApprovalFlowTemplateId?: string;
  name!: string;
  approvalFlow!: ApprovalFlow;

  constructor(obj: Partial<ApprovalFlow>) {
    super(obj);
    Object.assign(this, obj);
  }
}

export class ApprovalFlowTemplateContract extends BaseItem {
  ishtarTaskApprovalFlowTemplateId?: string;
  name!: string;
  approvalFlows!: ApprovalFlow[];

  constructor(obj: Partial<ApprovalFlow>) {
    super(obj);
    Object.assign(this, obj);
  }
}
