import {CheckListItem} from "../../domain/models/task/checklistItem";
import {Action, createReducer, on} from "@ngrx/store";
import {AppState} from "../../../app.reducer";
import {
  addCheckListItemResolved, getCheckListItemsByTaskIdResolved,
  getCheckListItemsResolved,
  removeCheckListItemResolved, updateCheckListItemsResolved,
} from "./checklist-item.actions";

export const featureSlice = 'checklistItem';

export interface State {
  checklistItems: CheckListItem[];
  checklistItem?: CheckListItem;
  ishtarTaskCheckListItemIds?: string[];
}

const defaultState: State = {
  checklistItems: [],
  checklistItem: undefined,
  ishtarTaskCheckListItemIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return checklistItemReducer(state, action);
}

export const initialState: State = defaultState;

export const checklistItemReducer = createReducer(
  initialState,
  on(getCheckListItemsByTaskIdResolved, (state, { checklistItems }) => {
    const newChecklistItems = checklistItems.filter((newItem) => (
      !state.checklistItems.some((existingItem) => existingItem.ishtarTaskCheckListItemId === newItem.ishtarTaskCheckListItemId)
    ));
    return {
      ...state,
      checklistItems: state.checklistItems.concat(newChecklistItems),
    };
  }),
  on(removeCheckListItemResolved, (state, { ishtarTaskCheckListItemId }) => ({
    ...state,
    checklistItems: state.checklistItems.filter(
      (checklistItem) => !ishtarTaskCheckListItemId.includes(checklistItem.ishtarTaskCheckListItemId!)
    ),
  } )),
  on(updateCheckListItemsResolved, (state, { updatedCheckListItems }) => ({
    ...state,
    checklistItems: state.checklistItems.map(
      (checklistItem) => updatedCheckListItems.find((u) => checklistItem.ishtarTaskCheckListItemId === u.ishtarTaskCheckListItemId) ?? checklistItem
    ),
  })),
  on(addCheckListItemResolved, (state, { addedCheckListItems }) => ({
    ...state,
    checklistItems: state.checklistItems.concat(addedCheckListItems),
  })),
  on(getCheckListItemsResolved, (state, { checklistItems }) => ({
    ...state,
    checklistItems: [...checklistItems],
  })),

);

export const checklistItemState = (state: AppState) => state.coreFeature.checklistItem;
