import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserLicenseInfo } from '../domain/models/user/user-license-info.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionService {
  apiBase = `${location.origin}/web`;
  constructor(private httpClient: HttpClient) {}

  getLicense(tenantId: string): Observable<UserLicenseInfo> {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.apiBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.apiBase}/session/keepalive`, {});
  }
}
