import {
  PROJECT_ISHTAR_PROJECT_ID,
  TASK_ISHTAR_PROJECT_ID,
  TITLE,
  TASK_TITLE,
  TYPE,
  TASK_TYPE,
  SKILL,
  TASK_SKILL,
  PARENT_TASK,
  TASK_PARENT_TASK,
  STATUS,
  TASK_STATUS,
  PROJECT,
  TASK_PROJECT,
  NAME,
  BATCH_NAME,
  BATCH_FREQUENCY,
  FREQUENCY,
  AMOUNT,
  BATCH_AMOUNT,
  BATCH_CLOSING_TIME,
  BATCH_EXPIRY_DATE,
  BATCH_START_DATE,
  CLOSING_TIME,
  EXPIRY_DATE,
  START_DATE,
} from '../data/constants';

export class SQLUtility {
  public static getTaskSQLColumnName(column: string): string {
    switch (column) {
      case PROJECT_ISHTAR_PROJECT_ID:
        return TASK_ISHTAR_PROJECT_ID;
      case TITLE:
        return TASK_TITLE;
      case TYPE:
        return TASK_TYPE;
      case SKILL:
        return TASK_SKILL;
      case PARENT_TASK:
        return TASK_PARENT_TASK;
      case STATUS:
        return TASK_STATUS;
      case PROJECT:
        return TASK_PROJECT;
      default:
        return column;
    }
  }

  public static getBatchSQLColumnName(column: string): string {
    switch (column) {
      case NAME:
        return BATCH_NAME;
      case FREQUENCY: 
        return BATCH_FREQUENCY;
      case AMOUNT:
        return BATCH_AMOUNT;
      case START_DATE:
        return BATCH_START_DATE;
      case EXPIRY_DATE:
        return BATCH_EXPIRY_DATE;
      case CLOSING_TIME:
        return BATCH_CLOSING_TIME;
      default:
        return column;
    }
  }
}
