import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ObjectUtils } from '../utils/object-utility';
import { CheckListItem } from '../domain/models/task/checklistItem';

@Injectable({ providedIn: 'root' })
export class ChecklistApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
  ) {}  

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  
  // this is not used in the app yet (only in the effect)
  public addCheckListItem(checklistItems: CheckListItem[]): Observable<CheckListItem[]> {
    const newCheckListItems = checklistItems.map((checklistItem) =>
      ObjectUtils.capitalizeKeys(checklistItem, 'Task')
    );

    const url = `${this.apiBase}/checklist/addCheckListItems`;
    return this.httpClient
      .post<CheckListItem[]>(
        url,
        newCheckListItems
      )
      .pipe(
        map((checklistItems) =>
          checklistItems.map(
            (checklistItem) =>
              new CheckListItem(ObjectUtils.camelcaseKeys(checklistItem))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  
  // this is not used in the app yet (only in the effect)
  public updateCheckListItems(checklistItems: CheckListItem[]): Observable<CheckListItem[]> {
    const updatedCheckListItems = checklistItems.map((checklistItem) =>
      ObjectUtils.capitalizeKeys(checklistItem, 'Task', 'CreatedBy', 'ModifiedBy')
    );

    const url = `${this.apiBase}/checklist/updateCheckListItems`;
    return this.httpClient
      .patch<CheckListItem[]>(
        url,
        updatedCheckListItems
      )
      .pipe(
        map((checklistItems) =>
          checklistItems.map(
            (checklistItem) =>
              new CheckListItem(ObjectUtils.camelcaseKeys(checklistItem))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  
  // this is not used in the app yet (only in the effect)
  public removeCheckListItem(checklistItemIds: string[]): Observable<string[]> {
    const url = `${this.apiBase}/checklist/removeCheckListItems`;
    return this.httpClient.delete<string[]>(
      url,
      {
        body: checklistItemIds,
      }
    );
  }
}
