import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Status } from '../domain/models/task/status';
import { ObjectUtils } from '../utils/object-utility';

@Injectable({ providedIn: 'root' })
export class LookupTypesApiService {
  apiBase = `${location.origin}/web/ishtartasks/types`;

  constructor(private httpClient: HttpClient) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////

  public getProjectTypes(): Observable<any[]> {
    const url = `${this.apiBase}/project`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getSkillTypes(): Observable<any[]> {
    const url = `${this.apiBase}/skill`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getStatusTypes(): Observable<Status[]> {
    const url = `${this.apiBase}/status`;
    return this.httpClient.get<any[]>(url).pipe(
      map((statuses) =>
        statuses.map((s) => new Status(ObjectUtils.camelcaseKeys(s)))
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getTaskTypes(): Observable<any[]> {
    const url = `${this.apiBase}/task`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getRegistrationTypes(): Observable<any[]> {
    const url = `${this.apiBase}/registration`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getDependencyTypes(): Observable<any[]> {
    const url = `${this.apiBase}/dependency`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getActionTypes(): Observable<any[]> {
    const url = `${this.apiBase}/action`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getLogTypes(): Observable<any[]> {
    const url = `${this.apiBase}/log`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getApprovalTypes(): Observable<any[]> {
    const url = `${this.apiBase}/approval`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getTriggerTypes(): Observable<any[]> {
    const url = `${this.apiBase}/trigger`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getChannelTypes(): Observable<any[]> {
    const url = `${this.apiBase}/channel`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getMetadataParameterTypes(): Observable<any[]> {
    const url = `${this.apiBase}/metadata-parameter`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getCountryTypes(): Observable<any[]> {
    const url = `${this.apiBase}/country`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getDayTypes(): Observable<any[]> {
    const url = `${this.apiBase}/day`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getMonthTypes(): Observable<any[]> {
    const url = `${this.apiBase}/month`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getRankingTypes(): Observable<any[]> {
    const url = `${this.apiBase}/ranking`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getNotificationFrequencyTypes(): Observable<any[]> {
    const url = `${this.apiBase}/frequency`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getResourceThingTypes(): Observable<any[]> {
    const url = `${this.apiBase}/resource-thing`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getResourceUserTypes(): Observable<any[]> {
    const url = `${this.apiBase}/resource-user`;
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
