import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ObjectUtils } from '../utils/object-utility';
import { TaskDependency } from '../domain/models/task/taskDependency';
import { DependencyValue } from '../domain/models/task/dependencyValue';

@Injectable({ providedIn: 'root' })
export class DependencyApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient
  ) {}  

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public addDependencies(dependencies: TaskDependency[]): Observable<TaskDependency[]> {
    const url = `${this.apiBase}/dependency/addDependencies`;
    const addedDependencies = ObjectUtils.capitalizeKeys(
      dependencies,
      'Task',
      'DependencyType',
      'DependentTask'
    );

    return this.httpClient
      .post<TaskDependency[]>(
        url,
        addedDependencies
      )
      .pipe(
        map((dependencies) =>
          dependencies.map((d) => new TaskDependency(ObjectUtils.camelcaseKeys(d)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }  
  
  public addDependencyValue(dependencyValues: DependencyValue[]): Observable<DependencyValue[]> {
    const url = `${this.apiBase}/dependency/addDependencyValues`;
    const addedDependencyValue = ObjectUtils.capitalizeKeys(
      dependencyValues,
      'TaskDependency',
      'Status'
    );

    return this.httpClient
      .post<TaskDependency[]>(
        url,
        addedDependencyValue
      )
      .pipe(
        map((dependencyValues) =>
          dependencyValues.map(
            (dependencyValue) =>
              new DependencyValue(ObjectUtils.camelcaseKeys(dependencyValue))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateDependencies(dependencies: TaskDependency[]): Observable<TaskDependency[]> {
    const url = `${this.apiBase}/dependency/updateDependencies`;
    const updatedDependencies = ObjectUtils.capitalizeKeys(
      dependencies,
      'IshtarTaskDependencyId',
      'Task',
      'DependencyType',
      'DependentTask',
      'CreatedBy',
      'ModifiedBy'
    );

    return this.httpClient
      .patch<TaskDependency[]>(
        url,
        updatedDependencies
      )
      .pipe(
        map((dependencies) =>
          dependencies.map((d) => new TaskDependency(ObjectUtils.camelcaseKeys(d)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // this is not used in the app yet (only in the effect)
  public updateDependencyValue(dependencyValues: DependencyValue[]): Observable<DependencyValue[]> {
    const url = `${this.apiBase}/dependency/updateDependencyValues`;
    const updatedDependencyValues = ObjectUtils.capitalizeKeys(
      dependencyValues,
      'IshtarTaskDependencyValueId',
      'TaskDependency',
      'CreatedBy',
      'ModifiedBy'
    );

    return this.httpClient
    .patch<DependencyValue[]>(
      url,
      updatedDependencyValues
    )
    .pipe(
      map((dependencyValues) =>
        dependencyValues.map(
          (dependencyValue) =>
            new DependencyValue(ObjectUtils.camelcaseKeys(dependencyValue))
        )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  public removeDependencies(ids: string[]): Observable<string[]> {
    const url = `${this.apiBase}/dependency/removeDependencies`;
    return this.httpClient.delete<string[]>(
      url,
      {
        body: [...new Set(ids)],
      }
    ).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public removeDependencyValue(ids: string[]): Observable<string[]> {
    const url = `${this.apiBase}/dependency/removeDependencyValues`;
    return this.httpClient.delete<string[]>(
      url,
      {
        body: [...new Set(ids)],
      }
    ).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
