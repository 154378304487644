import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { license$ } from '../data/data.observables';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

    // licenses = license$.value?.licenses || [];
    licenses = license$.pipe(map((l) => l?.licenses));

    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // return this.licenses.some((license) => {
        //     return license.productName === 'Ishtar.Tasks' && license.isAdmin === true;
        //   });
        return this.licenses.pipe(map((l) => l!.some((license) => {
            return license.productName === 'Ishtar.Tasks' && license.isAdmin === true;
          }
        )));
    }
}