import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

import { ApprovalFlow } from '../../domain/models/task/decision/approvalflow';
import {
  getApprovalFlowsResolved,
  saveApprovalFlowsResolved,
} from './approval.actions';
import {
  ApprovalFlowTemplateContract,
} from '../../domain/models/task/decision/approvalflowTemplate';

export const featureSlice = 'approvalflow';

export interface State {
  approvalFlows: ApprovalFlow[] | undefined;
  approvalFlowTemplates: ApprovalFlowTemplateContract[] | undefined;
}

const defaultState: State = {
  approvalFlows: undefined,
  approvalFlowTemplates: undefined,
};

export const initialState: State = defaultState;

const batchReducer = createReducer(
  initialState,
  on(getApprovalFlowsResolved, (state, { approvalFlows, taskId }) => ({
    ...state,
    approvalFlows: approvalFlows
      .filter((f) => f.task?.id !== taskId)
      .concat(approvalFlows),
  })),
  on(saveApprovalFlowsResolved, (state, { approvalFlows, taskId }) => ({
    ...state,
    approvalFlows: state.approvalFlows
      ?.filter((f) => f.task?.id !== taskId)
      .concat(approvalFlows),
  }))
);

export function Reducer(state: State | undefined, action: Action) {
  return batchReducer(state, action);
}

export const approvalState = (state: AppState) =>
  state.coreFeature.approvalflow;
