import { Lookup } from 'processdelight-angular-components';
import { Task } from '../task';
import { BatchDay } from './batchDay';
import { BatchMonth } from './batchMonth';
import { Frequency } from './frequency';
import { Month } from './month';
import { Ranking } from './ranking';

export class Batch {
  id?: string;
  ishtarTaskBatchId?: string;
  name?: string;
  active?: boolean;
  startDate?: string;
  closingTime?: number;
  expiryDate?: string;
  frequency?: Frequency;
  batchDays?: BatchDay[];
  batchMonths?: BatchMonth[];
  amount?: number;
  lastRun?: Date;
  rankings?: Ranking[];
  tasks?: Lookup[];

  constructor(obj: Partial<Batch>) {
    Object.assign(this, obj);
  }
}
