import { createAction, props } from '@ngrx/store';
import { Batch } from '../../domain/models/task/batch/batch';

export const getBatches = createAction(
    '[Batch] Get batches',
    props<{
        newPageSize: number;
        sortedColumn: string;
        sortDirection: string;
        filters: [string, any][];
        newPagingCookie?: string;
        callback?: () => void;
        error?: (e: any) => void;
    }>()
);

export const getBatchesResolved = createAction(
    '[Batch] Get Batches Resolved',
    props<{
        result: Batch[];
        pagingCookie: string;
        totalRecordCount: number;
        resetPaging?: boolean;
     }>()
);

export const addBatch = createAction(
    '[Batch] Add Batch',
    props<{
        batch: Batch;
        callback?: (batch : Batch) => void;
        error?: (e: any) => void;
    }>()
);

export const addBatchResolved = createAction(
    '[Batch] Add Batch Resolved',
    props<{
        addedBatch: Batch;
    }>()
);

export const updateBatch = createAction(
    '[Batch] Update Batch',
    props<{
        batch: Batch;
        callback?: (batch : Batch) => void;
        error?: (e: any) => void;
    }>()
);

export const updateBatchResolved = createAction(
    '[Batch] Update Batch Resolved',
    props<{batch: Batch }>()
  );

export const deleteBatch = createAction(
    '[Batch] Delete Batch',
    props<{
        ishtarTaskBatchId: string;
        callback?: (deletedBatchId: string) => void;
        error?: (e: any) => void;
    }>()
);

export const deleteBatchResolved = createAction(
    '[Batch] Delete Batch Resolved',
    props<{
        ishtarTaskBatchId: string;
    }>()
);
