import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  addCheckListItem,
  addCheckListItemResolved,
  getCheckListItemsByTaskId,
  getCheckListItemsByTaskIdResolved,
  removeCheckListItem,
  removeCheckListItemResolved,
  updateCheckListItems,
  updateCheckListItemsResolved,
  getCheckListItems,
  getCheckListItemsResolved,
} from './checklist-item.actions';
import { TaskApiService } from '../../services/task-api.service';
import { ChecklistApiService } from '../../services/checklist-api.service';

@Injectable({ providedIn: 'root' })
export class CheckListItemEffects {
  constructor(
    private actions$: Actions,
    private checklistApiService: ChecklistApiService,
    private taskApiService: TaskApiService
  ) {}

  getCheckListItemsByTaskId = createEffect(() =>
    this.actions$.pipe(
      ofType(getCheckListItemsByTaskId),
      mergeMap(({ taskId, callback }) =>
        this.taskApiService.getCheckListItemsByTaskId(taskId).pipe(
          tap((checklistItems) =>
            callback ? callback(checklistItems) : undefined
          ),
          mergeMap((checklistItems) =>
            of(
              getCheckListItemsByTaskIdResolved({
                checklistItems,
              })
            )
          ),
          catchError((e) => [])
        )
      )
    )
  );

  addCheckListItem = createEffect(() =>
    this.actions$.pipe(
      ofType(addCheckListItem),
      switchMap(({ checklistItems, callback, error }) =>
        this.checklistApiService.addCheckListItem(checklistItems).pipe(
          tap((checklistItem) =>
            callback ? callback(checklistItem) : undefined
          ),
          switchMap((addedCheckListItems) =>
            of(addCheckListItemResolved({ addedCheckListItems }))
          ),
          catchError((e) => {
            error?.(e);
            return [];
          })
        )
      )
    )
  );

  updateCheckListItems = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCheckListItems),
      switchMap(({ checklistItems }) =>
        this.checklistApiService.updateCheckListItems(checklistItems).pipe(
          switchMap((updatedCheckListItems) =>
            of(
              updateCheckListItemsResolved({
                updatedCheckListItems,
              })
            )
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeCheckListItems = createEffect(() =>
    this.actions$.pipe(
      ofType(removeCheckListItem),
      mergeMap(({ ishtarTaskCheckListItemId, callback }) =>
        this.checklistApiService
          .removeCheckListItem(ishtarTaskCheckListItemId)
          .pipe(
            tap(() => (callback ? callback() : undefined)),
            mergeMap((ishtarTaskCheckListItemId) =>
              of(removeCheckListItemResolved({ ishtarTaskCheckListItemId }))
            ),
            catchError((e) => [])
          )
      )
    )
  );

  getCheckListItems = createEffect(() =>
    this.actions$.pipe(
      ofType(getCheckListItems),
      switchMap(({ callback, error }) =>
        this.taskApiService.getCheckListItems().pipe(
          tap((checklistItems) =>
            callback ? callback(checklistItems) : undefined
          ),
          switchMap((checklistItems) =>
            of(
              getCheckListItemsResolved({
                checklistItems,
              })
            )
          ),
          catchError((e) => {
            error?.(e);
            return [];
          })
        )
      )
    )
  );
}
