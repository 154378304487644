import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardComponent, Filter, GroupUser } from 'processdelight-angular-components';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { Task } from '../domain/models/task/task.model';
import { ObjectUtils } from '../utils/object-utility';
import { FunctionsService } from './functions.service';
import { Attendee } from '../domain/models/task/attendee.model';
import { Kanban } from '../domain/models/task/kanban.model';
import { Checklist } from '../domain/models/task/checklist.model';
import { TimeRegistration } from '../domain/models/time/time-registration.model';
import { ResourceFunction } from '../domain/models/resource/resource-function.model';
import { Log } from '../domain/models/task/log.model';
import { DateTime } from 'luxon';
import { AvailableUser } from '../domain/models/available-user.model';
import { SuggestedTimeSlot } from '../domain/models/suggestedTimeSlot.model';
import { TemplateTask } from '../domain/models/task/template-task.model';

@Injectable({ providedIn: 'root' })
export class TaskApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public canTaskStart(id: string): Observable<{ label: string; dependencyName: string; }| undefined > {
    return this.httpClient
      .get<{ label: string; dependencyName: string; } | undefined>(`${this.apiBase}/task/${id}/canStart`)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTaskTranslations(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiBase}/task/translation`)
      .pipe(
        catchError((error) => {
          console.error(
            'An error occurred on task translations retrieval:',
            error
          );
          return throwError(error);
        })
      );
  }

  public getTaskUsersAndGroups(): Observable<{ users?: GroupUser[], groups?: GroupUser[] }> {
    return this.httpClient.get<{ users?: GroupUser[], groups?: GroupUser[] }>(`${this.apiBase}/task/user-group`)
      .pipe(
        catchError((error) => {
          console.error(
            'An error occurred on task users and groups retrieval:',
            error
          );
          return throwError(error);
        })
      );
  }

  public getTaskGroups(): Observable<GroupUser[]> {
    return this.httpClient.get<GroupUser[]>(`${this.apiBase}/task/group`)
      .pipe(
        catchError((error) => {
          console.error('An error occurred on task groups retrieval:', error);
          return throwError(error);
        })
      );
  }

  public getAllTasks(): Observable<Task[]> {
    return this.httpClient.get<Task[]>(`${this.apiBase}/task/all`)
      .pipe(
        map(
          (task) => task?.map((t) => new Task(ObjectUtils.camelcaseKeys(t))) || []
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTemplateTasks(): Observable<TemplateTask[]> {
    return this.httpClient.get<TemplateTask[]>(`${this.apiBase}/task/template`)
      .pipe(
        map(
          (task) => task?.map((t) => new TemplateTask(ObjectUtils.camelcaseKeys(t))) || []
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTasks(
    assignedToMe: boolean,
    orderBy: string,
    direction: string,
    filters: Filter[],
    pageSize: number,
    page: number
  ): Observable<{ result: Task[]; totalRecordCount: number; }> {
    const filter = DashboardComponent.createFilterString(filters);
    let url = `${this.apiBase}/task?assignedToMe=${assignedToMe}&orderBy=${orderBy}&direction=${direction}&pageSize=${pageSize}&page=${page}`;

    if (filter !== '') {
      url += `&filter=${filter}`;
    }

    return this.httpClient.get<{ result: Task[]; totalRecordCount: number; }>(url)
      .pipe(
        map((response) => ObjectUtils.camelcaseKeys(response)),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTaskDetails(id: string): Observable<Task> {
    return this.httpClient.get<Task>(`${this.apiBase}/task/${id}/details`)
      .pipe(
        map((task) => new Task(task)),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getSubTasks(taskId: string): Observable<Task[]> {
    return this.httpClient.get<Task[]>(`${this.apiBase}/task/${taskId}/sub-tasks`)
      .pipe(
        map((tasks) => tasks.map((t) => new Task(t))),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTaskById(id: string): Observable<Task> {
    return this.httpClient.get<Task>(`${this.apiBase}/task/${id}`)
      .pipe(
        map((task) => new Task(task)),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getChecklists(    
    orderBy: string,
    direction: string,
    filters: Filter[]
  ): Observable<Checklist[]> {
    const filter = DashboardComponent.createFilterString(filters);
    let url = `${this.apiBase}/checklist?orderBy=${orderBy}&direction=${direction}`;

    if (filter !== '') {
      url += `&filter=${filter}`;
    }

    return this.httpClient.get<Checklist[]>(url)
      .pipe(
        map(
          (checklists) => checklists.map((c) => new Checklist(c))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getKanban(assignedToMe: boolean): Observable<Kanban[]> {
    return this.httpClient.get<Kanban[]>(`${this.apiBase}/task/kanban?assignedToMe=${assignedToMe}`)
      .pipe(
        map(
          (task) => task?.map((t) => new Kanban(ObjectUtils.camelcaseKeys(t))) || []
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getLatestTaskNumber(): Observable<number> {
    return this.httpClient.get<number>(`${this.apiBase}/task/latest-task-number`)
      .pipe(
        map((latestTaskNumber) => latestTaskNumber),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTotalRegisteredTime(filters: Filter[]): Observable<TimeRegistration[]> {
    let url = `${this.apiBase}/time-registration`;
    const filter = DashboardComponent.createFilterString(filters);

    if (filter !== '') {
      url += `?filter=${filter}`;
    }

    return this.httpClient.get<TimeRegistration[]>(url)
      .pipe(
        map((timeRegistrations) =>
          timeRegistrations.map(
            (tr) => new TimeRegistration(ObjectUtils.camelcaseKeys(tr))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAvailableResources(startDate: string, endDate: string): Observable<{ availableUsers?: AvailableUser[]; resourceFunctions?: ResourceFunction[]; }> {
    return this.httpClient.get<{ availableUsers?: AvailableUser[]; resourceFunctions?: ResourceFunction[]; }>(`${this.apiBase}/task/available-resource?startDate=${startDate}&endDate=${endDate}`)
      .pipe(
        map((data) => ({
          availableUsers: data.availableUsers?.map(
            (u) => new AvailableUser(ObjectUtils.camelcaseKeys(u))
          ),
          resourceFunctions: data.resourceFunctions?.map(
            (rf) => new ResourceFunction(ObjectUtils.camelcaseKeys(rf))
          ),
        })),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public addTask(task: Task, createTaskChannel?: boolean): Observable<Task> {
    return this.httpClient.post<Task>(`${this.apiBase}/task`, task)
      .pipe(
        map((task) => new Task(task)),
        catchError((error) => {
          console.error('An error occurred on creating task:', error);
          return throwError(error);
        })
      );
  }

  public addLogs(logs: Log[]): Observable<Log[]> {
    return this.httpClient.post<Log[]>(`${this.apiBase}/log`, logs)
      .pipe(
        map((taskLogs) => 
          taskLogs.map((t) => new Log(ObjectUtils.camelcaseKeys(t)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public linkSubTasks(taskId: string, subTaskIds: string[]): Observable<Task[]> {
    return this.httpClient.post<Task[]>(`${this.apiBase}/task/${taskId}/link-sub-tasks`, subTaskIds)
      .pipe(
        map((subTasks) =>
          subTasks.map((st) => new Task(ObjectUtils.camelcaseKeys(st)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateTask(task: Task): Observable<Task> {
    return this.httpClient.patch<Task>(`${this.apiBase}/task/${task.id}`, task)
      .pipe(
        map((task) => new Task(task)),
        catchError((error) => {
          console.error('An error occurred on updating task:', error);
          return throwError(error);
        })
      );
  }

  public updateTaskStatus(taskId: string, statusId: string): Observable<Task> {
    const body = [
      {
        taskId: taskId,
        statusId: statusId,
      },
    ];

    return this.httpClient.patch<Task>(`${this.apiBase}/task/${taskId}/status`, body)
      .pipe(
        map((task) => new Task(task)),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  // soft delete so set IsDeleted to true
  public removeTask(id: string): Observable<string> {
    return this.httpClient.delete<Task>(`${this.apiBase}/task/${id}`)
      .pipe(
        map((task) => (ObjectUtils.camelcaseKeys(task) as Task).id!),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getAvailableSlots(attendees: GroupUser[] | undefined, durationMins: number, startDate: string | null| undefined, organizerUserId: string | undefined): Observable<SuggestedTimeSlot[]> {
    const body = {
      attendees: attendees,
      durationMins: durationMins,
      startDate: startDate,
      organizerUserId: organizerUserId
    };

    return this.httpClient.post<SuggestedTimeSlot[]>(`${this.apiBase}/task/available-slot`, body)
      .pipe(
        map((timeSlots) =>
          timeSlots.map(
            (ts) => ({
              startDateTime: ts.startDateTime,
              endDateTime: ts.endDateTime
            })
          )
        ),
        catchError((error) => {
          console.error('An error occurred on getting available slots:', error);
          return throwError(error);
        })
      );
  }

  public getAttendees(meetingId: string | undefined, organizerEmail: string | undefined): Observable<Attendee[]> {
    const body = {
      meetingId: meetingId,
      organizerEmail: organizerEmail
    };

    return this.httpClient.post<Attendee[]>(`${this.apiBase}/task/attendee`, body)
      .pipe(
        map((attendees) =>
          attendees.map((a) => new Attendee(ObjectUtils.camelcaseKeys(a)))
        ),
        catchError((error) => {
          console.error('An error occurred on getting attendees:', error);
          return throwError(error);
        })
      );
  }
}
