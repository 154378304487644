export const PROJECT_ISHTAR_PROJECT_ID = 'Project/IshtarProjectId';
export const TITLE = 'Title';
export const TYPE_TYPE = 'Type/Type';
export const TYPE = 'Type';
export const SKILL_TITLE = 'Skill/Title';
export const SKILL = 'Skill';
export const PARENT_TASK_TITLE = 'ParentTask/Title';
export const PARENT_TASK = 'ParentTask';
export const STATUS_STATUS = 'Status/Status';
export const STATUS = 'Status';
export const TASK_ISHTAR_PROJECT_ID = 'task.ishtar_project';
export const TASK_TITLE = 'task.ishtar_title';
export const TASK_TYPE = 'task.ishtar_typename';
export const TASK_SKILL = 'task.ishtar_skillname';
export const TASK_PARENT_TASK = 'task.ishtar_parenttaskname';
export const TASK_STATUS = 'task.ishtar_statusname';
export const PROJECT = 'Project';
export const TASK_PROJECT = 'task.ishtar_projectname';

export const NAME = 'Name';
export const BATCH_NAME = 'batch.ishtar_name';
export const FREQUENCY = 'Frequency';
export const BATCH_FREQUENCY = 'batch.ishtar_frequencyname';
export const AMOUNT = 'Amount';
export const BATCH_AMOUNT = 'batch.ishtar_amount';
export const START_DATE = 'StartDate';
export const BATCH_START_DATE = 'batch.ishtar_startdate';
export const EXPIRY_DATE = 'ExpiryDate';
export const BATCH_EXPIRY_DATE = 'batch.ishtar_expirydate';
export const CLOSING_TIME = 'ClosingTime';
export const BATCH_CLOSING_TIME = 'batch.ishtar_closingtime';