<processdelight-top-layout
  [buttonActions]="(buttonActions | async) || []"
  [appLogo]="appLogo"
  [iconActions]="iconActions"
  [sideBarActions]="navActions"
  [navColor]="(navColor$ | async) ?? '#124464'"
  [navContrast]="(navContrast$ | async) ?? '#fff'"
>
  <router-outlet></router-outlet>
</processdelight-top-layout>
