import { GroupUser } from 'processdelight-angular-components';
import { Task } from '../task/task';
import { DateTime } from 'luxon';

export class Resource {
  ishtarResourceId!: string;
  title!: string;
  task?: { id?: string; name?: string };
  function?: ResourceFunction | null;
  resourceUser?: ResourceUser | null;
  taskCapacity?: number;
  id?: string;
  modifiedOn?: DateTime;
  constructor(obj: Partial<Resource>) {
    Object.assign(this, obj);
  }
}

export class ResourceThing {
  ishtarResourceThingId!: string;
  name!: string;
  resourceFunctions: ResourceFunction[] = [];

  constructor(obj?: Partial<ResourceThing>) {
    Object.assign(this, obj);
  }
}

export class ResourceFunction {
  ishtarResourceFunctionId!: string;
  name!: string;
  resourceThing!: { id: string; name: string };
  description!: string;
  capacity!: number;
  timeSort!: string;

  constructor(obj?: Partial<ResourceFunction>) {
    Object.assign(this, obj);
  }
}

export class ResourceUser {
  ishtarResourceUserId!: string;
  capacity!: number;
  user!: GroupUser;
  availableUser?: AvailableUser;

  constructor(obj?: Partial<ResourceUser>) {
    Object.assign(this, obj);
  }
}

export class AvailableUser {
  userId!: string;
  available: 'FREE' | 'ASSIGNED' | 'OUTOFOFFICE' = 'FREE';
  reason?: string;

  constructor(obj?: Partial<AvailableUser>) {
    Object.assign(this, obj);
    if (typeof this.available === 'number') {
      switch (this.available) {
        case 0:
          this.available = 'FREE';
          break;
        case 1:
          this.available = 'ASSIGNED';
          break;
        case 2:
          this.available = 'OUTOFOFFICE';
          break;
      }
    }
  }
}
