import { Lookup } from '../lookup';

export class CheckListItem {
  ishtarTaskCheckListItemId?: string;
  task?: Lookup;
  value?: string;
  isChecked = false;
  position = 0;

  constructor(obj: Partial<CheckListItem>) {
    delete (obj as any).name;
    delete (obj as any).id;

    Object.assign(this, obj);
  }
}
