import { Lookup } from "./lookup";

export class BaseItem {
  modifiedOn?: Date;
  createdOn?: Date;
  modifiedBy?: Lookup;
  createdBy?: Lookup;

  constructor(obj: Partial<BaseItem>) {
    Object.assign(this, obj);
  }
}
