import { Action, createReducer, on } from '@ngrx/store';
import {
  addApplicationNotificationResolved,
  getApplicationNotificationsResolved,
  removeApplicationNotificationResolved,
  updateApplicationNotificationResolved,
} from './applicationNotification.action';
import { AppState } from 'src/app/app.reducer';
import { ApplicationNotificationDto } from 'processdelight-angular-components';

export const featureSlice = 'applicationNotification';

export interface State {
  applicationNotifications: ApplicationNotificationDto[] | undefined;
}

const defaultState: State = {
  applicationNotifications: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return applicationNotificationReducer(state, action);
}

export const initialState: State = defaultState;
export const applicationNotificationReducer = createReducer(
  initialState,
  on(getApplicationNotificationsResolved, (state, { result }) => ({
    ...state,
    applicationNotifications: [...result],
  })),
  on(
    addApplicationNotificationResolved,
    (state, { addedApplicationNotification }) => ({
      ...state,
      applicationNotifications: [addedApplicationNotification].concat([
        ...(state.applicationNotifications ?? []),
      ]),
    })
  ),
  on(
    updateApplicationNotificationResolved,
    (state, { applicationNotification }) => ({
      ...state,
      applicationNotifications: state.applicationNotifications?.map((p) =>
        p.ishtarApplicationNotificationId ==
        applicationNotification.ishtarApplicationNotificationId
          ? applicationNotification
          : p
      ),
    })
  ),
  on(removeApplicationNotificationResolved, (state, { ids }) => ({
    ...state,
    applicationNotifications: state.applicationNotifications?.filter(
      (p) => !ids.includes(p.ishtarApplicationNotificationId!)
    ),
  }))
);

export const applicationNotificationState = (state: AppState) =>
  state.coreFeature.applicationNotification;
