import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FunctionsService } from './functions.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import {
  ApplicationNotification,
  ApplicationNotificationDto,
  CreateNotificationrequest,
} from 'processdelight-angular-components';
import { appName } from './startup.service';
import { ObjectUtils } from '../utils/object-utility';

@Injectable({ providedIn: 'root' })
export class NotificationApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public getApplicationNotificationById(
    applicationNotificationId: string
  ): Observable<ApplicationNotification> {
    const url = `${this.apiBase}/application-notification/getNotificationById/${applicationNotificationId}`;
    return this.httpClient.get<ApplicationNotification>(url).pipe(
      map(
        (notification) =>
          new ApplicationNotification(
            ObjectUtils.camelcaseKeys(notification)
          )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getApplicationNotifications(
    itemId: string
  ): Observable<ApplicationNotificationDto[]> {
    const url = `${this.apiBase}/application-notification/getNotificationsByItemId/${appName}/${itemId}`;
    return this.httpClient.get<ApplicationNotificationDto[]>(url).pipe(
      map((notifications) =>
        notifications.map(
          (n) =>
            new ApplicationNotificationDto(
              ObjectUtils.camelcaseKeys(n)
            )
        )
      ),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getApplicationNotificationForGridById(
    applicationNotificationId: string
  ): Observable<ApplicationNotificationDto> {
    const url = `${this.apiBase}/application-notification/getApplicationNotificationForGridById/${applicationNotificationId}`;
    return this.httpClient
      .get<ApplicationNotificationDto>(url)
      .pipe(
        map(
          (n) =>
            new ApplicationNotificationDto(
              ObjectUtils.camelcaseKeys(n)
            )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public createApplicationNotification(
    applicationNotification: CreateNotificationrequest
  ): Observable<ApplicationNotificationDto> {
    const url = `${this.apiBase}/application-notification/createNotification`;
    return this.httpClient.post<ApplicationNotificationDto>(
      url,
      applicationNotification
    ).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  
  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateApplicationNotification(
    requestNotification: CreateNotificationrequest
  ): Observable<ApplicationNotificationDto> {
    const url = `${this.apiBase}/application-notification/updateNotification`;
    return this.httpClient.patch<ApplicationNotificationDto>(
      url,
      requestNotification
    ).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  public removeApplicationNotification(applicationNotificationId: string): Observable<string[]> {
    const url = `${this.apiBase}/application-notification/deleteNotification/${applicationNotificationId}`;
    return this.httpClient.delete<string[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
