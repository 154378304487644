import { ApprovalStatusEnum } from '../../../enums/approvalStatusEnum';
import { BaseItem } from '../../base-item';
import { Lookup } from '../../lookup';
import { ApprovalflowGroup } from './approvalflowGroup';

export class ApprovalFlowRequest extends BaseItem {
  ishtarTaskApprovalFlowId?: string;
  parentFlow?: Lookup;
  roundTitle?: string;
  task?: Lookup;
  isApproved?: ApprovalStatusEnum;
  isLocked = false;
  startTime?: string;
  timingInSeconds?: number;
  comment?: string;
  approvalFlowGroups?: ApprovalflowGroup[];

  constructor(obj: Partial<ApprovalFlowRequest>) {
    super(obj);
    Object.assign(this, obj);
  }
}
