import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuillModule } from 'ngx-quill';
import {
  DashboardComponent,
  FormBuilderComponent,
  UserInitialsComponent,
  SelectComponent
} from 'processdelight-angular-components';
import * as fromCoreFeature from './core/store/core.feature';
import { ApprovalStatusPipe } from './shared/approval-status/approval-status.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MaskitoModule } from '@maskito/angular';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NoSpaceDirective } from './shared/directives/no-space.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import {
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SnackBarService } from './core/services/snack-bar.service';

@NgModule({
  providers: [
    DatePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    SnackBarService
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature(fromCoreFeature.effects),
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaterialTimepickerModule,
    QuillModule.forRoot(),
    ApprovalStatusPipe,
    MatExpansionModule,
    MatDividerModule,
    UserInitialsComponent,
    DashboardComponent,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    SelectComponent,
    MatOptionModule,
    MatCardModule,
    MaskitoModule,
    MatCheckboxModule,
    NoSpaceDirective,
    FormBuilderComponent,
    MatTooltipModule,
    MatTabsModule,
    CdkDropList,
    CdkDrag,
    CdkDropListGroup,
    MatButtonToggleModule,
    DragDropModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    NgxMaterialTimepickerModule,
    QuillModule,
    MatExpansionModule,
    ApprovalStatusPipe,
    MatDividerModule,
    UserInitialsComponent,
    DashboardComponent,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    SelectComponent,
    MatOptionModule,
    MatCardModule,
    MaskitoModule,
    MatCheckboxModule,
    NoSpaceDirective,
    FormBuilderComponent,
    MatTooltipModule,
    MatTabsModule,
    CdkDropList,
    CdkDrag,
    CdkDropListGroup,
    MatSlideToggleModule,
    MatButtonToggleModule,
    DragDropModule,
  ],
  declarations: [],
})
export class CoreModule {}
