export class Status {
  id?: string;
  name?: string;
  ishtarStatusId!: string;
  status!: string;
  color?: string;
  isFinalState?: boolean;
  type!: string[];
  inShortMenu?: boolean;
  sortNumber?: number;
  isDeleted?: boolean;

  constructor(obj: Partial<Status>) {
    Object.assign(this, obj);
  }
}
