import { GroupUser } from "processdelight-angular-components";

export class Project {
    ishtarProjectId?: string;
    projectName!: string;
    projectId?: string;
    client?: { id?: string; name?: string };
    status!: { id?: string; name?: string };
    description?: string;
    preStudy?: boolean;
    calculateProgress?: boolean;
    startDate?: string;
    deadline?: string;
    projectType?: { id?: string; name?: string };
    projectVersion?: string;
    projectChannel?: string;
    progress?: number;
    productOwner?: GroupUser;
    owners?: GroupUser[];
    members?: GroupUser[];
    isTemplate?: boolean;

    constructor(obj: Partial<Project>) {
        Object.assign(this, obj);
    }
}

export class ProjectHolder {
    ishtarProjectId?: string;
    projectName!: string;
    projectId?: string;

    constructor(obj: Partial<ProjectHolder>) {
        Object.assign(this, obj);
    }
}
