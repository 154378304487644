import { createAction, props } from '@ngrx/store';
import { TaskDependency } from '../../domain/models/task/taskDependency';
import { DependencyValue } from '../../domain/models/task/dependencyValue';
import { ActionBase } from '../action-base.interface';

export const removeDependencies = createAction(
  '[Dependency] Remove dependencies',
  props<{ ishtarTaskDependencyIds: string[] } & ActionBase<string[]>>()
);

export const removeDependenciesResolved = createAction(
  '[Dependency] Remove dependencies resolved',
  props<{ ishtarTaskDependencyIds: string[] }>()
);

export const updateDependencies = createAction(
  '[Dependency] Update dependencies',
  props<{ dependencies: TaskDependency[] } & ActionBase<TaskDependency[]>>()
);

export const updateDependenciesResolved = createAction(
  '[Dependency] Update dependency resolved',
  props<{ updatedDependencies: TaskDependency[] }>()
);

export const addDependencies = createAction(
  '[Dependency] Add dependencies',
  props<
    {
      dependencies: TaskDependency[];
    } & ActionBase<TaskDependency[]>
  >()
);

export const addDependenciesResolved = createAction(
  '[Dependency] Add dependencies resolved',
  props<{ addedDependencies: TaskDependency[] }>()
);

export const updateDependencyValues = createAction(
  '[DependencyValues] Update dependency values',
  props<
    { dependencyValues: DependencyValue[] } & ActionBase<DependencyValue[]>
  >()
);

export const updateDependencyValuesResolved = createAction(
  '[DependencyValues] Update dependency values resolved',
  props<{ updatedDependencyValues: DependencyValue[] }>()
);

export const addDependencyValues = createAction(
  '[DependencyValues] Add dependency values',
  props<
    { dependencyValues: DependencyValue[] } & ActionBase<DependencyValue[]>
  >()
);

export const addDependencyValuesResolved = createAction(
  '[DependencyValues] Add dependency values resolved',
  props<{ addedDependencyValues: DependencyValue[] }>()
);

export const removeDependencyValues = createAction(
  '[DependencyValues] Remove dependency values',
  props<{ ishtarDependencyValueIds: string[] } & ActionBase<string[]>>()
);

export const removeDependencyValuesResolved = createAction(
  '[DependencyValues] Remove dependency values resolved',
  props<{ ishtarDependencyValueIds: string[] }>()
);
