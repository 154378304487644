/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
  Filter,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import { environment } from 'src/environments/environment';
import { SQLUtility } from '../utils/sql-utility';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  apiBase = `${environment.ishtarFunctions}/api`;

  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  public createApiEndpointUrl(path: string): string {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }
  
  //////////////////////////////////////////////////////////////////

  filterQuery(filters: string[]): string {
    let filterString = '';
    if (filters.length > 0) {
      // const filterString = '&filter=';
      filterString += '&filter=';
      return filterString + filters.join(' and ');
    } else return filterString;
  }

  getDateFilter(columnName: string, filterValue: any) {
    if (filterValue[0] && filterValue[1]) {
      return `${columnName} >= '${DateTime.fromJSDate(filterValue[0]).toFormat(
        'yyyy-MM-dd'
      )}' and ${columnName} <= '${DateTime.fromJSDate(filterValue[1]).toFormat(
        'yyyy-MM-dd'
      )}'`;
    } else if (filterValue[0]) {
      return `${columnName} >= '${DateTime.fromJSDate(filterValue[0]).toFormat(
        'yyyy-MM-dd'
      )}'`;
    } else if (filterValue[1]) {
      return `${columnName} <= '${DateTime.fromJSDate(filterValue[1]).toFormat(
        'yyyy-MM-dd'
      )}'`;
    } else if (filterValue == false) {
      return `${columnName} is null`;
    } else if (filterValue == true) {
      return `${columnName} is not null`;
    } else {
      return '';
    }
  }

  filterActiveQuery(filters: [string, any][]): string {
    if (filters.length > 0) {
      const filterString =
        '&expand=Status&select=*,Status&filter=IsDeleted eq false and ';
      return (
        filterString +
        filters.map((filter) => `(${filter[0]} ne ${filter[1]})`).join(' and ')
      );
    } else {
      return '&filter=IsDeleted eq false';
    }
  }

  filterTimeRegistrationsQuery(filters: Filter[]) {
    if (filters.length > 0) {
      const filterString = '&expand=Task&select=Task,StartDate,EndDate&filter=';
      return (
        filterString +
        filters
          .map((filter) => `(${filter.columnName} eq '${filter.value}')`)
          .join(' and ')
      );
    } else {
      return '';
    }
  }

  filterTaskDocumentsQuery(taskId: string): string {
    if (taskId) {
      return `&expand=Item,Task&select=*,Item,Task&filter=Task/IshtarTaskId eq ${taskId} and Item ne null`;
    } else {
      return '';
    }
  }
}
