import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Batch } from '../domain/models/task/batch/batch';
import { ObjectUtils } from '../utils/object-utility';
import { SQLUtility } from '../utils/sql-utility';

@Injectable({ providedIn: 'root' })
export class BatchApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient
  ) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public getBatches(
    newPageSize: number,
    sortedColumn: string,
    sortDirection: string,
    filters: [string, any][],
    newPagingCookie?: string
  ): Observable<{
    result: Batch[];
    pagingCookie: string;
    totalRecordCount: number;
  }> {
    const url = `${this.apiBase}/batches/getBatches?${
      newPagingCookie ? `pagingCookie=${newPagingCookie}&` : ''
    }doPaging=true&retrieveTotalRecordCount=true&top=${newPageSize}${this.batchOrderByQuery(
      sortedColumn,
      sortDirection
    )}${this.sqlFilterQuery(filters)}`;
    return this.httpClient
      .get<{
        result: Batch[];
        pagingCookie: string;
        totalRecordCount: number;
      }>(url)
      .pipe(
        map(({ result, pagingCookie, totalRecordCount }) => ({
          result: result.map((t) => new Batch(ObjectUtils.camelcaseKeys(t))),
          pagingCookie,
          totalRecordCount,
        })),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public addBatch(batch: Batch): Observable<Batch> {
    const url = `${this.apiBase}/batches/addBatch`;
    const addedBatch = ObjectUtils.capitalizeKeys(
      batch,
      'Frequency',
      'Ranking',
      'Tasks',
      'Rankings',
      'BatchDays',
      'BatchMonths'
    );

    return this.httpClient.post<Batch>(url, addedBatch).pipe(
      map((batch) => new Batch(ObjectUtils.camelcaseKeys(batch))),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateBatch(batch: Batch): Observable<Batch> {
    const url = `${this.apiBase}/batches/updateBatch`;
    const updatedBatch = ObjectUtils.capitalizeKeys(
      batch,
      'Frequency',
      'Ranking',
      'Tasks',
      'Rankings',
      'BatchDays',
      'BatchMonths'
    );
    return this.httpClient.patch<Batch>(url, updatedBatch).pipe(
      map((batch) => new Batch(ObjectUtils.camelcaseKeys(batch))),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  public removeBatch(batchId: string): Observable<string> {
    const url = `${this.apiBase}/batches/removeBatch/${batchId}`;
    return this.httpClient.delete(url, { responseType: 'text' }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /////////////////////////////////////////////////// Utils ///////////////////////////////////////////////////
  private sqlFilterQuery(filters: [string, any][]): string {
    if (filters.length > 0) {
      const filterString = '&filter=';
      return (
        filterString +
        filters
          .map((filter) => {
            switch (filter[0]) {
              default:
                return `${filter[0]} LIKE '%${filter[1]}%'`;
            }
          })
          .join(' and ')
      );
    } else {
      return '';
    }
  }

  private batchOrderByQuery(column: string, direction: string): string {
    if (!column || !direction) return '';
    else
      return `&orderBy=${SQLUtility.getBatchSQLColumnName(
        column
      )} ${direction.toUpperCase()}`;
  }
}
