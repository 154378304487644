import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApprovalFlow } from '../domain/models/task/decision/approvalflow';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DateTime } from 'luxon';
import { ApprovalFlowTemplateContract } from '../domain/models/task/decision/approvalflowTemplate';
import { ApprovalFlowRequest } from '../domain/models/task/decision/approvalflow-request';
import { ObjectUtils } from '../utils/object-utility';

@Injectable({ providedIn: 'root' })
export class ApprovalFlowApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient
  ) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public getApprovalFlows(taskId: string): Observable<ApprovalFlow[]> {
    const url = `${this.apiBase}/approvalFlows/getApprovalFlows?taskId=${taskId}`;
    return this.httpClient
      .get<ApprovalFlow[]>(url)
      .pipe(
        map((approvalFlows: any[]) => {
          return approvalFlows.map(
            (a) => new ApprovalFlow(ObjectUtils.camelcaseKeys(a))
          );
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // this method is not used yet 
  public getApprovalFlowTemplates(): Observable<ApprovalFlowTemplateContract[]> {
    const url = `${this.apiBase}/approvalFlows/getApprovalFlowTemplates`;
    return this.httpClient
      .get<ApprovalFlowTemplateContract[]>(url)
      .pipe(
        map((approvalFlows) => {
          return approvalFlows.map(
            (a) => new ApprovalFlowTemplateContract(ObjectUtils.camelcaseKeys(a))
          );
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public saveApprovalFlows(taskId: string, approvalFlows: ApprovalFlow[]): Observable<ApprovalFlow[]> {
    const url = `${this.apiBase}/approvalFlows/saveApprovalFlows?taskId=${taskId}`;
    const data = approvalFlows.map((f) => new ApprovalFlowRequest({
      ...f,
      startTime: f.startTime
        ? DateTime.fromJSDate(f.startTime, { zone: 'utc' }).toFormat(
            'dd/MM/yyyy HH:mm'
          )
        : undefined,
    }));
    return this.httpClient
      .post<ApprovalFlow[]>(
        url,
        data
      )
      .pipe(
        map((approvalFlows) => {
          return approvalFlows.map(
            (a) => new ApprovalFlow(ObjectUtils.camelcaseKeys(a))
          );
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // this method is not used yet
  public saveApprovalFlowTemplate(template: ApprovalFlowTemplateContract) {
    const url = `${this.apiBase}/approvalFlows/saveApprovalFlowTemplate`;
    return this.httpClient
      .post<ApprovalFlowTemplateContract>(
        url,
        template
      )
      .pipe(
        map((template) => {
          return new ApprovalFlowTemplateContract(ObjectUtils.camelcaseKeys(template));
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
