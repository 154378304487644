import { createAction, props } from '@ngrx/store';
import { DMSItem, DMSMetadata } from '../../domain/models/dms/dms-item.model';
import {
  KanbanContract,
  Task,
  TaskContract,
} from '../../domain/models/task/task';
import { TaskDocumentContract } from '../../domain/models/task/taskDocument.contract';
import { TaskLog } from '../../domain/models/task/taskLog';
import { ActionBase } from '../action-base.interface';
import { Status } from '../../domain/models/task/status';

export const getTasks = createAction(
  '[Task] Get Tasks',
  props<{
    newPageSize: number;
    sortedColumn: string;
    sortDirection: string;
    assignedToMe: boolean;
    filters: [string, any][];
    newPageNumber?: number;
    initialLoading?: boolean;
    resetCache?: boolean;
    callback?: (tasks: Task[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getTasksResolved = createAction(
  '[Task] Get Tasks Resolved',
  props<{
    result: Task[];
    pageNumber: number;
    totalRecordCount: number;
    assignedToMe: boolean;
    resetPaging?: boolean;
  }>()
);

export const getAllTasks = createAction(
  '[Task] Get All Tasks',
  props<{
    callback?: () => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getAllTasksResolved = createAction(
  '[Task] Get All Tasks Resolved',
  props<{
    tasks: TaskContract[];
  }>()
);

export const getTask = createAction(
  '[Task] Get Task',
  props<
    {
      ishtarTaskId: string;
      errorCallback?: (error: any) => void;
    } & ActionBase<{ task: Task }>
  >()
);

export const getTaskResolved = createAction(
  '[Task] Get Task Resolved',
  props<{
    task: Task;
  }>()
);

export const addTask = createAction(
  '[Task] Add Task',
  props<
    {
      task: Task;
      localTimeZoneId: string;
      errorCallback?: (error: any) => void;
    } & ActionBase<Task>
  >()
);

export const addTaskResolved = createAction(
  '[Task] Add Task Resolved',
  props<{
    addedTask: Task;
  }>()
);

export const updateTask = createAction(
  '[Task] Update Task',
  props<
    {
      task: Task;
      localTimeZoneId: string;
      errorCallback?: (error: any) => void;
    } & ActionBase<Task>
  >()
);
export const updateTaskStatus = createAction(
  '[Task] Update Task status',
  props<
    {
      taskId: string;
      statusId: string;
    } & ActionBase<{ tasks: Task }>
  >()
);

export const updateTaskResolved = createAction(
  '[Task] Update Task Resolved',
  props<Task>()
);

export const deleteTask = createAction(
  '[Task] Delete Task',
  props<
    {
      ishtarTaskId: string;
      errorCallback?: (error: any) => void;
    } & ActionBase<string>
  >()
);

export const deleteTaskResolved = createAction(
  '[Task] Delete Task Resolved',
  props<{
    ishtarTaskId: string;
  }>()
);

export const addTaskLogs = createAction(
  '[Task] Add Task Logs',
  props<{ logs: TaskLog[] } & ActionBase<TaskLog[]>>()
);

export const addTaskLogsResolved = createAction(
  '[Task] Add Task Logs Resolved',
  props<{ logs: TaskLog[] }>()
);

export const getTaskDocuments = createAction(
  '[Task] Get Task Documents',
  props<{
    taskId: string;
    callback?: (taskDocuments: DMSItem[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getTaskDocumentsResolved = createAction(
  '[Task] Get Task Documents Resolved',
  props<{
    taskDocuments: DMSItem[];
  }>()
);

export const createTaskDocuments = createAction(
  '[Task] Create Task Documents',
  props<
    {
      taskDocumentRequests: TaskDocumentContract[];
    } & ActionBase<{
      createdItems: DMSItem[];
      updatedMetadata: DMSMetadata[];
    }>
  >()
);

export const createTaskDocumentsResolved = createAction(
  '[Task] Create Task Documents Resolved',
  props<{
    contract: {
      createdItems: DMSItem[];
      updatedMetadata: DMSMetadata[];
    };
  }>()
);

export const removeDocuments = createAction(
  '[Task] Remove document from a Task',
  props<
    {
      taskId: string;
      dmsAppItemIds: string[];
    } & ActionBase<string[]>
  >()
);

export const removeDocumentsResolved = createAction(
  '[Task] Remove document from a Task Resolved',
  props<{
    taskId: string;
    dmsAppItemIds: string[];
  }>()
);

export const getSubTasks = createAction(
  '[Task] Get Subtasks',
  props<{
    parentTaskId: string;
    callback?: (subTasks: Task[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getSubTasksResolved = createAction(
  '[Task] Get Subtasks Resolved',
  props<{
    subTasks: Task[];
  }>()
);

export const linkSubtasks = createAction(
  '[Task] Link Subtasks to a specific task',
  props<
    {
      taskId: string;
      subtaskIds: string[];
    } & ActionBase<{
      subtasks: Task[];
    }>
  >()
);

export const linkSubtasksResolved = createAction(
  '[Task] Link Subtasks to a specific task Resolved',
  props<{
    subtasks: Task[];
  }>()
);

export const deleteStatus = createAction(
  '[Status] Delete Status',
  props<
    {
      ishtarStatusId: string;
    } & ActionBase<string>
  >()
);

export const deleteStatusResolved = createAction(
  '[Status] Delete Status Resolved',
  props<{
    ishtarStatusId: string;
  }>()
);

export const getStatuses = createAction(
  '[Statuses] Get statuses',
  props<ActionBase<Status[]>>()
);

export const getStatusesResolved = createAction(
  '[Statuses] Get statuses resolved',
  props<{ statuses: Status[] }>()
);

export const updateStatuses = createAction(
  '[Statuses] Update statuses',
  props<{ statuses: Status[] } & ActionBase<Status[]>>()
);

export const updateStatusesResolved = createAction(
  '[Statuses] Update statuses resolved',
  props<{ updatedStatuses: Status[] }>()
);

export const addStatuses = createAction(
  '[Statuses] Add statuses',
  props<{ statuses: Status[] } & ActionBase<Status[]>>()
);

export const addStatusesResolved = createAction(
  '[Statuses] Add statuses resolved',
  props<{ addedStatuses: Status[] }>()
);

export const getKanban = createAction(
  '[Kanban] Get kanban',
  props<
    {
      assignedToMe: boolean;
    } & ActionBase<{
      tasks: KanbanContract[];
    }>
  >()
);

export const getKanbanResolved = createAction(
  '[Kanban] Get kanban Resolved',
  props<{
    tasks: KanbanContract[];
  }>()
);
