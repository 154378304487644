import { Lookup } from "processdelight-angular-components";

export class UpdateStatusTaskRequest {
    ishtarTaskId!: string;
    status?: Lookup;

    constructor(obj: Partial<UpdateStatusTaskRequest>) {
        Object.assign(this, obj);
    }
}
