import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DMSItem, DMSMetadata } from '../domain/models/dms/dms-item.model';
import { TaskDocumentContract } from '../domain/models/task/taskDocument.contract';
import { ObjectUtils } from '../utils/object-utility';

@Injectable({ providedIn: 'root' })
export class TaskDocumentsApiService {
  apiBase = `${location.origin}/web`;

  constructor(private httpClient: HttpClient) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public getTaskDocuments(taskId: string): Observable<DMSItem[]> {
    const url = `${this.apiBase}/taskDocuments/${taskId}/getTaskDocuments`;
    return this.httpClient.get<DMSItem[]>(url).pipe(
      map((taskDocuments) => taskDocuments.map(
        (item) => new DMSItem(ObjectUtils.camelcaseKeys(item))
      )),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /////////////////////////////////////////////////// CREATE ///////////////////////////////////////////////////
  public createTaskDocuments(
    fileUploadRequest: TaskDocumentContract[]
  ): Observable<{
    createdItems: DMSItem[];
    updatedMetadata: DMSMetadata[];
  }> {
    const url = `${this.apiBase}/taskDocuments/createTaskDocuments`;
    return this.httpClient
      .post<{
        createdItems: DMSItem[];
        updatedMetadata: DMSMetadata[];
      }>(url, fileUploadRequest)
      .pipe(
        map((contract) => ({
          createdItems: contract.createdItems.map(
            (item) => new DMSItem(ObjectUtils.camelcaseKeys(item))
          ),
          updatedMetadata: contract.updatedMetadata.map(
            (item) => new DMSMetadata(ObjectUtils.camelcaseKeys(item))
          ),
        })),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  public removeTaskDocuments(dmsItemAppIds: string[]): Observable<string[]> {
    const url = `${this.apiBase}/taskDocuments/removeTaskDocuments`;
    return this.httpClient.delete<string[]>(url, { body: dmsItemAppIds });
  }
}
