import {Lookup} from "../lookup";

export class DependencyValue {
    ishtarTaskDependencyValueId?: string;
    taskDependency!: Lookup;
    value!: string;

    constructor(obj: DependencyValue) {
        delete (obj as any).id ;
        delete (obj as any).name ;
        Object.assign(this, obj);
    }
}
