import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  getApprovalFlows,
  getApprovalFlowsResolved,
  onApprovalFlowActionResolved,
  saveApprovalFlows,
  saveApprovalFlowsResolved,
} from './approval.actions';
import { ApprovalFlowApiService } from '../../services/approval-flow-api.service';

@Injectable({ providedIn: 'root' })
export class ApprovalFlowEffects {
  constructor(
    private actions$: Actions,
    private approvalFlowApiService: ApprovalFlowApiService
  ) {}

  getApprovalFlows = createEffect(() =>
    this.actions$.pipe(
      ofType(getApprovalFlows),
      mergeMap(({ taskId, callback, error }) =>
        this.approvalFlowApiService.getApprovalFlows(taskId).pipe(
          tap((approvalFlows) =>
            callback ? callback(approvalFlows) : undefined
          ),
          switchMap((approvalFlows) =>
            of(
              getApprovalFlowsResolved({
                taskId: taskId,
                approvalFlows: approvalFlows || [],
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  saveApprovalFlows = createEffect(() =>
    this.actions$.pipe(
      ofType(saveApprovalFlows),
      mergeMap(({ taskId, approvalFlows, callback, error }) =>
        this.approvalFlowApiService
          .saveApprovalFlows(taskId, approvalFlows)
          .pipe(
            catchError((e) => {
              error ? error(e) : undefined;
              return of(undefined);
            }),
            tap((data) =>
              callback
                ? callback({
                    approvalFlows: data || [],
                    taskId: taskId,
                  })
                : undefined
            ),
            switchMap((data) => {
              const actions: any[] = [
                saveApprovalFlowsResolved({
                  approvalFlows: data || [],
                  taskId: taskId,
                }),
                onApprovalFlowActionResolved({
                  approvalFlows: data || [],
                }),
              ];
              return actions;
            }),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );
}
