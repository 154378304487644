import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideStore } from '@ngrx/store';
import {
  StoreDevtoolsModule,
  provideStoreDevtools,
} from '@ngrx/store-devtools';
import { QuillModule, provideQuillConfig } from 'ngx-quill';
import {
  APP_STARTUP,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_FUNCTIONS_SCOPE,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_INSTANCE,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_URL,
  MsalModule,
  MyDateLocale,
  microsoftAuthenticationInterceptor,
} from 'processdelight-angular-components';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { StartUpService } from './app/core//services/startup.service';
import { license$ } from './app/core/data/data.observables';
import { CheckListItemFacade } from './app/core/store/checklist-item/checklist-item.facade';
import { TaskFacade } from './app/core/store/task/task.facade';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    { provide: ISHTAR365_LAST_ROUTE_KEY, useValue: environment.lastRouteKey },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['tasks'] },
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(StartUpService);
        return () => service.boot();
      },
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true, firstDayOfWeek: 1 },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: MyDateLocale(license$.value?.language || 'en'),
    },
    provideRouter(appRoutes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(
      withInterceptors([microsoftAuthenticationInterceptor('')])
    ),
    provideQuillConfig({}),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      QuillModule.forRoot(),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MsalModule,
      MatDialogModule,
    ),
    provideAnimations(),
    TaskFacade,
    CheckListItemFacade
  ]
});
