import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ObjectUtils } from '../utils/object-utility';
import { Status } from '../domain/models/task/status';

@Injectable({ providedIn: 'root' })
export class StatusApiService {
  apiBase = `${location.origin}/web`;

  constructor(private httpClient: HttpClient) {}

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public addStatuses(statuses: Status[]): Observable<Status[]> {
    const url = `${this.apiBase}/status/addStatuses`;
    const addedStatuses = statuses.map((s) => ObjectUtils.capitalizeKeys(s));
    return this.httpClient
      .post<Status[]>(
        url,
        addedStatuses
      )
      .pipe(
        map((statuses) =>
          statuses.map((s) => new Status(ObjectUtils.camelcaseKeys(s)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateStatuses(statuses: Status[]): Observable<Status[]> {
    const url = `${this.apiBase}/status/updateStatuses`;
    const updatedStatuses = statuses.map((s) =>
      ObjectUtils.capitalizeKeys({ ...s, id: undefined, name: undefined })
    );
    return this.httpClient
      .patch<Status[]>(
        url,
        updatedStatuses
      )
      .pipe(
        map((statuses) =>
          statuses.map((s) => new Status(ObjectUtils.camelcaseKeys(s)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////

  // soft delete so set IsDeleted to true
  public removeStatus(id: string): Observable<string> {
    const url = `${this.apiBase}/status/${id}/remove`;
    return this.httpClient.delete(url, { responseType: 'text' }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
