import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from '../../../app.reducer';
import { CheckListItem } from '../../domain/models/task/checklistItem';
import {
  addCheckListItem,
  getCheckListItems,
  getCheckListItemsByTaskId,
  removeCheckListItem,
  updateCheckListItems,
} from './checklist-item.actions';
import { checklistItemState } from './checklist-item.reducer';

@Injectable({ providedIn: 'root' })
export class CheckListItemFacade {
  checklistItems$ = this._store.pipe(
    select(checklistItemState),
    map((state) => state.checklistItems)
  );

  constructor(private _store: Store<AppState>) {}

  getCheckListItemsByTaskId$(taskId: string) {
    const subject = new Subject<CheckListItem[]>();
    this._store.dispatch(
      getCheckListItemsByTaskId({
        taskId,
        callback: (checklistItems) => {
          subject.next(checklistItems);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getCheckListItems$() {
    const subject = new Subject<CheckListItem[]>();
    this._store.dispatch(
      getCheckListItems({
        callback: (checklistItems) => {
          subject.next(checklistItems);
          subject.complete();
        },
        error: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  addCheckListItems$(checklistItems: CheckListItem[]) {
    const subject = new Subject<CheckListItem[]>();
    this._store.dispatch(
      addCheckListItem({
        checklistItems,
        callback: (checklistItems) => {
          subject.next(checklistItems);
          subject.complete();
        },
        error: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  removeCheckListItems$(ishtarTaskCheckListItemId: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeCheckListItem({
        ishtarTaskCheckListItemId,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateCheckListItems(checklistItems: CheckListItem[]) {
    this._store.dispatch(updateCheckListItems({ checklistItems }));
  }
}
