import * as fromTask from './task/task.reducer';
import * as fromIshtarTime from './ishtar-time/ishtar-time.reducer';
import * as fromDependency from './dependency/dependency.reducer';
import * as fromCheckListItem from './checklist-item/checklist-item.reducer';
import * as fromBatch from './batch/batch.reducer';
import * as fromApproval from './approval-flow/approval.reducer';
import * as fromApplicationNotification from './applicationNotification/applicationNotification.reducer';

import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { TaskEffects } from './task/task.effects';
import { DependencyEffects } from './dependency/dependency.effects';
import { CheckListItemEffects } from './checklist-item/checklist-item.effects';
import { BatchEffects } from './batch/batch.effects';
import { ApprovalFlowEffects } from './approval-flow/approval.effects';
import { ApplicationNotificationEffects } from './applicationNotification/applicationNotification.effect';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromTask.featureSlice]: fromTask.State;
  [fromIshtarTime.featureSlice]: fromIshtarTime.State;
  [fromDependency.featureSlice]: fromDependency.State;
  [fromCheckListItem.featureSlice]: fromCheckListItem.State;
  [fromBatch.featureSlice]: fromBatch.State;
  [fromApproval.featureSlice]: fromApproval.State;
  [fromApplicationNotification.featureSlice]: fromApplicationNotification.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromTask.featureSlice]: fromTask.Reducer,
      [fromIshtarTime.featureSlice]: fromIshtarTime.Reducer,
      [fromDependency.featureSlice]: fromDependency.Reducer,
      [fromCheckListItem.featureSlice]: fromCheckListItem.Reducer,
      [fromBatch.featureSlice]: fromBatch.Reducer,
      [fromApproval.featureSlice]: fromApproval.Reducer,
      [fromApplicationNotification.featureSlice]:
        fromApplicationNotification.Reducer,
    }),
  }
);
export const effects = [
  TaskEffects,
  DependencyEffects,
  CheckListItemEffects,
  BatchEffects,
  ApprovalFlowEffects,
  ApplicationNotificationEffects,
];
