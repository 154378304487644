import {createAction, props} from "@ngrx/store";
import {CheckListItem} from "../../domain/models/task/checklistItem";

export const getCheckListItemsByTaskId = createAction(
  '[CheckListItem] Get checklistItems by taskId',
  props<{
    taskId: string;
    callback?: (checklistItems: CheckListItem[]) => void;
  }>()
);

export const getCheckListItemsByTaskIdResolved = createAction(
  '[CheckListItem] Get checklistItems by taskId resolved',
  props<{
    checklistItems: CheckListItem[];
  }>()
);

export const getCheckListItems = createAction(
  '[CheckListItem] Get checklistItems',
  props<{
    callback?: (checklistItems: CheckListItem[]) => void;
    error?: (error: any) => void;
  }>()
);


export const getCheckListItemsResolved = createAction(
  '[CheckListItem] Get checklistItems resolved',
  props<{
    checklistItems: CheckListItem[];
  }>()
);

export const removeCheckListItem = createAction(
  '[CheckListItem] Remove checklistItem',
  props<{ ishtarTaskCheckListItemId: string[]; callback?: () => void }>()
);

export const removeCheckListItemResolved = createAction(
  '[CheckListItem] Remove checklistItem resolved',
  props<{ ishtarTaskCheckListItemId: string[] }>()
);

export const updateCheckListItems = createAction(
  '[CheckListItem] Update checklistItems',
  props<{ checklistItems: CheckListItem[]; callback?: () => void }>()
);

export const updateCheckListItemsResolved = createAction(
  '[CheckListItem] Update checklistItem resolved',
  props<{ updatedCheckListItems: CheckListItem[] }>()
);

export const addCheckListItem = createAction(
  '[CheckListItem] Add checklistItem',
  props<{ 
    checklistItems: CheckListItem[]; 
    callback?: (checklistItems: CheckListItem[]) => void;
    error?: (error: any) => void;
  }>()
);

export const addCheckListItemResolved = createAction(
  '[CheckListItem] Add checklistItem resolved',
  props<{ addedCheckListItems: CheckListItem[] }>()
);
