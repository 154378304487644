import { AdminGuard } from './core/guards/admin.guard';
import { Routes } from '@angular/router';
import { PreventNavigationGuard } from './core/guards/prevent-navigation.guard';

import {
    ActionBarComponent,
    ActionBarService,
    BreadcrumbsComponent,
    ContextMenuAction,
    DashboardComponent,
    GeneralLayoutComponent,
    LoaderComponent,
    MicrosoftAuthenticationGuard,
    SideBarAction,
    SideBarComponent,
    TopBarComponent,
    TopLayoutComponent,
    TranslationService,
  } from 'processdelight-angular-components';

export const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('processdelight-angular-components').then((m) => m.MsalModule),
    },
    {
        path: '404',
        loadComponent: () =>
            import('./not-found/not-found.component').then(
                (m) => m.NotFoundComponent
            ),
    },
    {
        path: 'tasks',
        canActivate: [MicrosoftAuthenticationGuard],
        loadComponent: () =>
            import('./task-dashboard/task-dashboard.component').then(
                (mod) => mod.TaskDashboardComponent
            ),
        data: { breadcrumbTitle: 'Tasks' },
    },
    {
        path: 'details/:id',
        canActivate: [MicrosoftAuthenticationGuard],
        loadComponent: () =>
            import('./task-dashboard/task-detail/task-detail.component').then(
                (mod) => mod.TaskDetailComponent
            ),
        children: [
            {
                path: 'task info',
                loadComponent: () =>
                    import(
                        './task-dashboard/task-detail/task-form/task-form.component'
                    ).then((mod) => mod.TaskFormComponent),
            },
            {
                path: 'task progress',
                loadComponent: () =>
                    import(
                        './task-dashboard/task-detail/task-progress/task-progress.component'
                    ).then((mod) => mod.TaskProgressComponent),
            },
        ],
    },
    {
        path: 'settings',
        canActivate: [MicrosoftAuthenticationGuard, AdminGuard],
        loadComponent: () =>
            import('./settings/settings.component').then(
                (mod) => mod.SettingsComponent
            ),
        data: { breadcrumbTitle: 'Settings' },
        children: [
            {
                path: 'statuses',
                canDeactivate: [PreventNavigationGuard],
                loadComponent: () =>
                    import('./settings/statuses/statuses.component').then(
                        (mod) => mod.StatusesComponent
                    ),
                data: { breadcrumbTitle: 'Statuses' },
            },
            {
                path: 'batches',
                loadComponent: () =>
                    import('./settings/batches/batches.component').then(
                        (mod) => mod.BatchesComponent
                    ),
                data: { breadcrumbTitle: 'Batches' },
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'statuses',
            }
        ],
    },
    {
        path: 'kanban',
        canActivate: [MicrosoftAuthenticationGuard],
        loadComponent: () =>
            import('./kanban/kanban.component').then((mod) => mod.KanbanComponent),
        data: { breadcrumbTitle: 'Kanban' },
    },
    {
        path: '**',
        redirectTo: '',
    },
];