import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { ApplicationNotificationDto, CreateNotificationrequest } from 'processdelight-angular-components';

export const getApplicationNotifications = createAction(
  '[ApplicationNotification] Get applicationNotifications',
  props<{
    itemId: string;
    callback?: (applicationNotifications: ApplicationNotificationDto[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getApplicationNotificationsResolved = createAction(
  '[ApplicationNotification] Get applicationNotifications resolved',
  props<{
    result: ApplicationNotificationDto[];
  }>()
);
export const updateApplicationNotification = createAction(
  '[ApplicationNotification] Update applicationNotification',
  props<{
    applicationNotification: CreateNotificationrequest;
    callback?: (applicationNotification: ApplicationNotificationDto) => void;
    error?: (error: any) => void;
  }>()
);

export const updateApplicationNotificationResolved = createAction(
  '[ApplicationNotification] Update applicationNotification resolved',
  props<{ applicationNotification: ApplicationNotificationDto }>()
);

export const removeApplicationNotification = createAction(
  '[ApplicationNotification] Remove applicationNotification',
  props<
    {
      id: string;
    } & ActionBase<string[]>
  >()
);

export const removeApplicationNotificationResolved = createAction(
  '[ApplicationNotification] Remove applicationNotification resolved',
  props<{ ids: string[] }>()
);

export const addApplicationNotification = createAction(
  '[ApplicationNotification] Add applicationNotification',
  props<{
    applicationNotification: CreateNotificationrequest;
    callback?: (applicationNotification: ApplicationNotificationDto) => void;
    error?: (error: any) => void; 
  }>()
);

export const addApplicationNotificationResolved = createAction(
  '[ApplicationNotification] Add applicationNotification resolved',
  props<{ addedApplicationNotification: ApplicationNotificationDto }>()
);
