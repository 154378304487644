import { GroupUser, Lookup } from 'processdelight-angular-components';

export class IshtarTimeRegistration {
  ishtarTimeRegistrationId?: string;
  description?: string;
  endDate?: string;
  startDate?: string;
  user?: GroupUser;
  project?: Lookup;
  task?: Lookup;
  timeSort?: Lookup;

  constructor(obj: Partial<IshtarTimeRegistration>) {
    Object.assign(this, obj);
  }
}
