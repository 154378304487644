import { Action, createReducer, on } from '@ngrx/store';
import { IshtarTimeRegistration } from '../../domain/models/time/ishtarTimeRegistration';
import {
  getIshtarTimesByTaskIdResolved,
  addIshtarTimeResolved,
  deleteIshtarTimeResolved,
  updateIshtarTimeResolved,
} from './ishtar-time.actions';
import { AppState } from 'src/app/app.reducer';

export const featureSlice = 'ishtarTime';

export interface State {
  ishtarTimes: IshtarTimeRegistration[];
  ishtarTime?: IshtarTimeRegistration;
  ishtarTimeId?: string;
}
const defaultState: State = {
  ishtarTimes: [],
  ishtarTime: undefined,
  ishtarTimeId: undefined,
};

export const initialState: State = defaultState;

const ishtarTimeReducer = createReducer(
  initialState,
  on(getIshtarTimesByTaskIdResolved, (state, { ishtarTimes }) => ({
    ...state,
    ishtarTimes: [...ishtarTimes],
  })),
  on(addIshtarTimeResolved, (state, { addedIshtarTime }) => ({
    ...state,
    ishtarTimes: [addedIshtarTime].concat([...state.ishtarTimes]),
  })),
  on(deleteIshtarTimeResolved, (state, { ishtarTimeRegistrationId }) => ({
    ...state,
    ishtarTimes: state.ishtarTimes.filter(
      (t) => t.ishtarTimeRegistrationId != ishtarTimeRegistrationId
    ),
  })),
  on(updateIshtarTimeResolved, (state, { updatedIshtarTime }) => ({
    ...state,
    ishtarTimes: state.ishtarTimes.map((t) =>
      t.ishtarTimeRegistrationId == updatedIshtarTime.ishtarTimeRegistrationId
        ? updatedIshtarTime
        : t
    ),
  }))
);

export function Reducer(state: State | undefined, action: Action) {
  return ishtarTimeReducer(state, action);
}

export const IshtarTimeState = (state: AppState) =>
  state.coreFeature.ishtarTime;
